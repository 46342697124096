import cx from 'classnames';
import { groupBy } from 'lodash';
import React from 'react';

import Breadcrumbs from '@/components/common/Breadcrumbs';
import BrandListItem from '@/components/section/Brand/BrandListItem';
import Seo from '@/components/seo';
import { useBrandImagesQuery } from '@/query/brand_images';
import { useBrandsQuery } from '@/query/brands';
import { ROUTE_NAMES } from '@/utils/url';

import * as style from './marcas.module.scss';

const BrandsPage = () => {
  const brandsQuery = useBrandsQuery();
  const brandImagesQuery = useBrandImagesQuery();

  const brands = brandsQuery.allGoogleSpreadsheetBrands.edges.map((e) => e.node);
  const brandImages = brandImagesQuery.allFile.edges.reduce((acc, img) => {
    const brandName = img.node.relativePath.split('/')[1];
    return { ...acc, [brandName]: img.node };
  }, {});
  const groupedBrands = groupBy(brands, 'isFeatured');
  const featuredBrands = groupedBrands['TRUE'];
  const regularBrands = groupedBrands['FALSE'];
  const breadcrumbs = [{ url: `/${ROUTE_NAMES.BRANDS}`, name: 'Marcas' }];

  return (
    <>
      <Seo
        title="Marcas"
        description="Trabajamos marcas de primera línea a través de importadores directos, para asegurar la calidad y legitimidad de todos nuestros productos."
      />
      <section className={style.main}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <h1>Nuestras Marcas</h1>
        <p className={style.lead}>
          Trabajamos marcas de primera línea a través de importadores directos, para asegurar la
          calidad y legitimidad de todos nuestros productos.
        </p>
        <ul className={cx(style.brandsList, style.featuredBrands)}>
          {featuredBrands.map((brand) => (
            <BrandListItem brand={brand} image={brandImages[brand.uuid]} key={brand.uuid} />
          ))}
        </ul>
        <ul className={style.brandsList}>
          {regularBrands.map((brand) => (
            <BrandListItem brand={brand} image={brandImages[brand.uuid]} key={brand.uuid} />
          ))}
        </ul>
      </section>
    </>
  );
};

export default BrandsPage;
