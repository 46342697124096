import { graphql, useStaticQuery } from 'gatsby';

export const useBrandImagesQuery = () => {
  return useStaticQuery(graphql`
    query brandImages {
      allFile(filter: { relativeDirectory: { regex: "/marcas/" } }) {
        edges {
          node {
            relativePath
            extension
            publicURL
          }
        }
      }
    }
  `);
};
