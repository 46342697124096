import cx from 'classnames';
import { Link } from 'gatsby';
import React from 'react';

import { ROUTE_NAMES } from '@/utils/url';

import * as style from './brandlistitem.module.scss';

const BrandListItem = ({ brand, image, isImageOnly, className }) => {
  return (
    <li className={cx(style.brandItem, className, isImageOnly && style.isImageOnly)}>
      <Link className={style.brandItemLink} to={`/${ROUTE_NAMES.BRANDS}/${brand.uuid}`}>
        {isImageOnly ? null : <h3 className={style.brandName}>{brand.name}</h3>}
        <div className={style.image}>
          {image ? (
            <img className={style.svgImage} src={image && image.publicURL} alt={brand.name} />
          ) : (
            <span className={style.brandNameFallback}>{brand.name}</span>
          )}
        </div>
        {isImageOnly ? null : (
          <span className={style.productCount}>
            <strong>{brand.productCount}</strong>{' '}
            {`producto${brand.productCount === '1' ? '' : 's'}`}
          </span>
        )}
      </Link>
    </li>
  );
};

export default BrandListItem;
